@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");
@import "base";
@import "icons/fontawesome.scss";

@font-face {
  font-family: ubuntu;
  src: url("/assets/fonts/Ubuntu-Regular.ttf");
}
:root {
  --bs-body-font-size: 1.2rem;
}

html {
  font-size: 14px;
}

body {
  background-color: white;
}

.navbar {
  margin-bottom: 0;
}

body {
  font-size: 17px;
  line-height: 2;
  font-family: ubuntu, "Montserrat", sans-serif;
  line-height: 2rem;
  font-weight: 200;
}

// img {
//   height: auto;
//   width: 100%;
// }

.partner-logos{
  img{
    width: 80px;
  }
}

nav {
  background-color: #0766AD;
  margin-bottom: 3rem;
  h3 {
    padding: 20px 0;
    color: white;
    font-weight: 600;
  }
  ul.navbar-nav {
    .nav-item {
      .nav-link {
        color: white;
        margin: 0 1rem;
        &:hover {
          border-bottom: 1px solid white;
          padding-bottom: 5px;
          color: white;
        }
      }
    }
  }
}

.contact-badge{
  background-color: #C5E898;
  text-align: center;
  color: black;
  a{
    color: black;
  }
}

.slider {
  background-image: url(/assets/img/houtbewerker.jpg);
  height: 40rem;
  background-position: center center;
  background-size: cover;
  clip-path: polygon(0 0, 100% 0, 100% 52%, 0 100%);
  h2 {
    position: relative;
    color: white;
    left: 10rem;
    padding-top: 5rem;
    font-size: 34px;
    font-weight: bold;
  }

  a {
    color: white;
    padding: 1.5rem 3rem;
    background-color: black;
    font-weight: bold;
    font-size: 2rem;
  }

  &-button {
    color: white;
    padding: 1.5rem 3rem;
    font-weight: bold;
    font-size: 2rem;
    color: white;
    text-align: center;
    display: block;
    position: relative;
    margin-top: 5rem;
    &:hover {
      color: white;
    }
  }
}

.blocks {
  margin: -9rem 0 5rem 0;
  &__block {
    margin: 0 2rem;
    background-color: green;

    height: 10rem;
    h2 {
      text-align: center;
      padding: 2rem;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      color: white;
    }
  }
}
.news {
  &_item {
    color: black;
    a {
      color: black;
    }
    &-image {
      height: 200px;
      // width: 100%;
      background-position: center center;
      background-size: cover;
    }
  }
}
.quote {
  font-style: italic;
  font-size: 25px;
}

.button {
  text-align: center;
  margin: 2rem 0;
  &__inner {
    padding: 1.5rem 3rem;
    background-color: black;
    font-weight: bold;
    font-size: 2rem;
    color: white;
    &:hover {
      color: white;
    }
  }
}

.alignleft {
  float: left;
  text-align: left;
  margin: 1rem 3rem 1rem 0;
}

.alignright {
  float: right;
  text-align: right;
  margin: 1rem 0 1rem 1rem;
}

.footer {
  .contact {
    p {
      margin: 0;
    }
  }
}

// .contact{
//     background-color: rgba(0, 0, 0, 0.747);
//     padding: 2rem 0;
//     color: white;
//     a{
//         color: white;
//     }
// }

.img-fluid-max-height {
  max-width: 100%;
  height: 100%;
}

.faq {
  &-item {
    background-color: rgb(228, 228, 228);
    padding: 8px;
    margin: 10px 0;
    &-question {
      text-decoration: underline;
    }
  }
}

.cta-form {
  margin: 20px;
  text-align: center;
  a {
    background-color: rgba(255, 162, 0, 0.878);
    border: none;
    border-radius: 3px;
    color: rgb(255, 255, 255);
    font-size: 20px;
    padding: 10px;
    margin: 10px 15px;
  }
}


.home{
  &_block{
    padding: 0 10px;
    a{
      color: black;
    }
    &:hover{
      color: black;
    }
  }
  &.convince{
    font-size: 20px;
    li{
      padding: 10px 0;
    }
  }
  &_price-tag{
    display: inline;
    position: absolute;
    top: 10px;
    right: 35px;
    font-weight: bold;
    background-color: #F3F3F3;
    padding: 10px;
    font-size: 25px;
    border-radius: 100px;
    max-width: 150px;
    p{
      font-size: 12px;
      line-height: 15px;
    }
  }
  &_poppen{
    &_pop{
      background-color: #29ADB2;
      border-radius: 3px;
      text-align: center;
      img{
        width: 100%;
      }
    }
  }
}
.application-form {
  background-color: #0175aabf;
  color: white;
  border-radius: 1px solid slategray;
  h2 {
    font-weight: bold;
    text-align: center;
    margin: 15px 0;
  }

  .client-details {
    background-color: rgba(0, 0, 0, 0.21);
    padding: 10px 10px 15px 10px;
    margin-bottom: 20px;

    div {
      margin: 10px 0;
    }
  }

  #appointment_button {
    background-color: rgba(255, 162, 0, 0.878);
    border: none;
    border-radius: 3px;
    color: rgb(255, 255, 255);
    font-size: 20px;
    padding: 10px;
  }
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
  font-style: italic;
}

strong {
  font-weight: bold;
}

footer {
  background-color: #bdbdbd;
  color: black;
  font-size: 1rem;
  a {
    color: black;
  }
  p {
    margin: 1rem 0;
  }
}

.garage-sidebar {
  text-align: center;
  color: white;
  .appointment {
    background-color: rgba(19, 145, 19, 0.959);
    margin: 8px 0;
    padding: 10px;
  }
  .review {
    background-color: #0175aa;
    margin: 8px 0;
    padding: 10px;
  }
  .activate_account {
    background-color: #c0c0c0;
    margin: 8px 0;
    padding: 10px;
  }
}

.convince {
  background-color: #C5E898;
  border: 2px solid #5a6f3d;
  border-radius: 3px;
  list-style: none;
  padding-left: 5px;
  line-height: 1, 3;
  &_item {
    margin: 4px 0;
    &::before {
      content: "\f00c"; /* FontAwesome Unicode */
      font-family: FontAwesome;
      display: inline-block;
      margin-left: calc(var(--icon-space) * -1);
      margin: 0 10px;
      color: white;
    }
  }
}

@media only screen and (max-width: 450px) {
  .slider h2 {
    left: 0;
    text-align: center;
  }
  .slider a {
    display: inherit;
  }
  .slider-button {
    margin-top: 2rem;
  }

  .blocks__block {
    margin: 2rem 0;
  }
}
